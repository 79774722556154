import React, {useEffect, useState} from 'react';
import {useAuthentication} from "../hooks/AuthenticationHook";

const AuthenticationContext = React.createContext({
    user: {},
    setUser: () => {
    }
});

const AuthenticationProvider = ({children}) => {
    const [user, setUser] = useState({})
    let {currentUser} = useAuthentication()


    useEffect(() => {
        if (Object.keys(currentUser ?? {}).length > 0) {
            setUser(currentUser)
        }
    }, [])

    return <AuthenticationContext.Provider value={{user, setUser}}>
        {children}
    </AuthenticationContext.Provider>
};

export {AuthenticationContext, AuthenticationProvider}
