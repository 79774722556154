import React, {useState} from 'react';

const CrumbsContext = React.createContext({
    crumbs: [],
    setCrumbs: () => {
    }
});

const CrumbsContextProvider = ({children}) => {
    const [crumbs, setCrumbs] = useState([])

    return <CrumbsContext.Provider value={{crumbs, setCrumbs}}>
        {children}
    </CrumbsContext.Provider>
};

export {CrumbsContextProvider, CrumbsContext}
