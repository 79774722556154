import React, {useState} from 'react';

const LoaderContext = React.createContext({
    loading: [],
    setLoading: () => {
    }
});

const LoaderContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false)

    return <LoaderContext.Provider value={{loading, setLoading}}>
        {children}
    </LoaderContext.Provider>
};

export {LoaderContextProvider, LoaderContext}
